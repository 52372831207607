import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Tracker from "./tracker"

const StyledCard = styled.div`
  border-radius: 2rem;
  @media (min-width: 769px) {
    margin-top: 5rem;
  }
`
const Progress = (props) => {
  return (
    <div className="columns is-centered is-vcentered mx-1">
      <div className="column is-8">
        <StyledCard className="card">
          <div className="card-content">
            <h1 className="title has-text-centered">Progress</h1>
            <div>
              <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth mt-3">
                <tbody>
                  {props.pages.map((page, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          <button className="button is-small is-text is-pulled-left mr-4">
                            <Tracker id={page.id} />
                          </button>
                          <Link to={`/${page.id}`}>
                            {page.Title ? page.Title : page.Name}
                          </Link>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </StyledCard>
      </div>
    </div>
  )
}

export default Progress
