import React, { useEffect } from "react"
import { navigate, graphql } from "gatsby"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import useAuth from "../hooks/useAuth"
import Progress from "../components/progress"
import Account from "../components/account"

const App = (props) => {
  const { state, isAuthenticated } = useAuth()
  const redirect = props.location.pathname.split("/").pop()
  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login", { state: { redirect } })
    }
  }, [isAuthenticated, redirect])

  const getAllPages = (query) => {
    let nodes = []
    query.allStrapiConcentrationgame.nodes.map((data) => {
      return nodes.push(data)
    })
    query.allStrapiPracticepage.nodes.map((data) => {
      return nodes.push(data)
    })
    query.allStrapiPathpage.nodes.map((data) => {
      return nodes.push(data)
    })
    query.allStrapiSpeedgrid.nodes.map((data) => {
      return nodes.push(data)
    })
    query.allStrapiWordfamily.nodes.map((data) => {
      return nodes.push(data)
    })
    return nodes
  }

  return (
    <Layout>
      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
      <Router basepath="/app">
        <Account path="/account" user={state.user} />
        <Progress path="/progress" pages={getAllPages(props.data)} default />
      </Router>
    </Layout>
  )
}
export default App

export const pageQuery = graphql`
  query ProgressQuery {
    allStrapiConcentrationgame {
      nodes {
        id
        Title
      }
    }
    allStrapiPracticepage {
      nodes {
        id
        Name
      }
    }
    allStrapiPathpage {
      nodes {
        id
        Title
      }
    }
    allStrapiSpeedgrid {
      nodes {
        id
        Name
      }
    }
    allStrapiWordfamily {
      nodes {
        id
        Title
      }
    }
  }
`
