import React, { useState, useEffect } from "react"
import { FaStar, FaRegStar } from "react-icons/fa"
import useAuth from "../hooks/useAuth"

const Star = (props) => {
  if (props.complete) {
    return <FaStar size={props.size} />
  } else {
    return <FaRegStar size={props.size} />
  }
}

const Tracker = (props) => {
  const { state, update } = useAuth()
  let baseStatus = false
  if (state.user.progress) {
    if (state.user.progress.includes(props.id)) {
      baseStatus = true
    }
  }
  const [status, updateStatus] = useState(baseStatus)

  useEffect(() => {
    let progress = state.user.progress
    if (status !== baseStatus) {
      if (status) {
        progress.push(props.id)
      } else {
        progress = progress.filter((item) => !props.id.includes(item))
      }
      update(progress)
    }
  }, [status, baseStatus, update, state.user.progress, props.id])

  return (
    <div
      onClick={() => updateStatus((prevStatus) => !prevStatus)}
      onKeyPress={() => updateStatus((prevStatus) => !prevStatus)}
      role="button"
      tabIndex="0"
      className={props.type === "check" ? undefined : "has-text-warning"}
      style={{ display: "inline" }}
    >
      {props.type === "check" ? (
        <label className="checkbox">
          <input type="checkbox" checked={status} />
            {" "}Done
        </label>
      ) : (<Star complete={status} size="1.5em" />)}
    </div>
      )
}

export default Tracker
