import React from "react"
import { Link } from "gatsby"
import { FaUserCircle, FaEnvelope, FaLock } from "react-icons/fa"
import styled from "styled-components"

const StyledCard = styled.div`
  border-radius: 2rem;
  @media (min-width: 769px) {
    margin-top: 5rem;
  }
`

const Account = (props) => (
  <div className="columns is-centered is-vcentered mx-1">
    <div className="column is-8">
      <StyledCard className="card">
        <div className="card-content">
          <h1 className="title has-text-centered">Account</h1>

          <div className="notification is-warning has-text-centered">
            Sorry, accounts cannot be edited at this time. Please contact Ed to
            make changes.
          </div>

          <div className="field">
            <label className="label" htmlFor="username">
              Username
            </label>
            <div className="control has-icons-left">
              <input
                id="username"
                className="input"
                type="text"
                placeholder="Username"
                value={props.user.username}
                disabled
              />
              <span className="icon is-small is-left">
                <FaUserCircle />
              </span>
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="email">
              Email
            </label>
            <div className="control has-icons-left ">
              <input
                id="email"
                className="input"
                type="email"
                placeholder="Email"
                value={props.user.email}
                disabled
              />
              <span className="icon is-small is-left">
                <FaEnvelope />
              </span>
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="password">
              Password
            </label>
            <div className="control has-icons-left">
              <input
                value=""
                id="password"
                type="password"
                placeholder="***************"
                className="input"
                disabled
              />
              <span className="icon is-small is-left">
                <FaLock />
              </span>
            </div>
          </div>

          <div className="field is-grouped is-grouped-centered">
            <div className="control">
              <button className="button is-success" disabled>
                Submit
              </button>
            </div>
            <div className="control">
              <Link to="/">
                <button className="button is-light">Cancel</button>
              </Link>
            </div>
          </div>
        </div>
      </StyledCard>
    </div>
  </div>
)

export default Account
